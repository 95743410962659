<template>
  <div class="status">
    <div
      v-if="servicesStatus.errors"
      class="api-error">
      <img
        :src="errorImage(servicesStatus.errors[0].code)"
        :alt="servicesStatus.errors[0].message">
      <h2
        v-if="_.lowerCase(servicesStatus.errors[0].code) !== 'unexpected'"
        class="api-error__message">
        {{ servicesStatus.errors[0].message }}
      </h2>
    </div>
    <div v-else>
      <div class="status__header">
        <v-icon
          :color="getStatusIcon(servicesStatus.status).color"
          size="80">
          {{ getStatusIcon(servicesStatus.status).icon }}
        </v-icon>
        <h1>{{ _.upperFirst(servicesStatus.message) }}</h1>
      </div>
      <div class="internal-services__list">
        <div
          v-for="{ canonical, status, message } of servicesStatus.checks"
          :key="canonical"
          class="internal-services__item">
          <v-icon
            :color="getStatusIcon(status).color">
            {{ getStatusIcon(status).icon }}
          </v-icon>
          <div class="internal-services__item--content">
            <h3>{{ _.upperFirst(canonical) }}</h3>
            <p>{{ message }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="version">
      <p class="app-version mt-4">
        {{ $t('youAreUsing') }}
        <strong>Cycloid {{ appVersion }}</strong>
        <span v-if="apiVersion">
          {{ $t('and') }} <strong>Cycloid API {{ apiVersion.version }}</strong>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { errorsIllu } from '@/assets/images'
import { constructBreadcrumb, getStatusIcon } from '@/utils/helpers'

export default {
  name: 'CyPageStatus',
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.status'))
  },
  header () {
    return { title: this.$t('serviceStatus') }
  },
  data: () => ({
    appVersion: process.env.VUE_APP_VERSION,
  }),
  computed: {
    ...mapState({
      apiVersion: (state) => state.apiVersion,
      servicesStatus: (state) => state.servicesStatus,
    }),
  },
  methods: {
    getStatusIcon,
    errorImage (errorCode) {
      return _.lowerCase(errorCode) === 'unexpected' ? errorsIllu.error500 : errorsIllu.other
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.status',
        serviceStatus: 'Service status',
      },
      es: {
        title: '@:routes.status',
        serviceStatus: 'Estado del servicio',
      },
      fr: {
        title: '@:routes.status',
        serviceStatus: 'Etat du service',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
  color: get-color("primary");

  &__back-btn {
    display: flex;
    position: fixed;
    top: 1em;
    left: 1em;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    font-size: map.get($font-sizes, "lg");

    &:hover {
      background-color: map.get($grey, "light-2");
      cursor: pointer;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 240px;
    margin-bottom: 1em;
    background-position: center;
    background-size: contain;
    text-align: center;

    .v-icon {
      margin-bottom: 0.2em;
    }
  }

  .internal-services {
    &__list {
      padding: 24px;
      border-radius: 8px;
      background-color: white;
      box-shadow: map.get($box-shadow, "card");
    }

    &__item {
      display: flex;
      flex-direction: row;
      margin: 2px 0;

      .v-icon {
        align-self: flex-start;
        margin-top: 0.1em;
        margin-right: 8px;
      }
    }
  }

  .api-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 400px;

    img {
      width: 100%;
    }

    &__message {
      color: get-color("secondary");
    }
  }

  .app-version {
    font-size: map.get($font-sizes, "sm");
  }
}
</style>
